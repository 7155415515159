import styled from 'styled-components';
import { mobile } from '../responsive';
// import MapImg from '../assets/images/map2.jpg';
import React from 'react';
import Iframe from './RwIframe';


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
`;
const SectionHead = styled.div`
    
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
    })}
`;
const LocationInfo = styled.div`
    display: flex;
    width: 100%;
    margin: 40px 0px;
    justify-content: space-between;
    ${mobile({ 
        flexDirection: "column",
        padding: "0px 20px"
    })}
`;
const AddressWrap = styled.div`
    width: 38%;
    margin-left: 5%;
    ${mobile({ 
        width: "100%",
        marginLeft: '0',
        textAlign: "center",
        marginBottom: "40px"
    })}
`;
const AddressText = styled.div`
    color: #323232;
    font-size: 28px;
    font-weight: 600;
`;
const Time = styled.div`
     width: 35%;
     margin-right: 5%;
     ${mobile({ 
        width: "100%",
    })}
`;
const Group = styled.div`
    margin-bottom: 20px;
    text-align: right;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Field = styled.div`
    color: #bbb9b9;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    white-space: nowrap;
`;
const Value = styled.div`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
`;
const Map = styled.div`
    width: 90%;
    /* height: 400px; */
    /* background-color: #a6a6a7; */
    margin-bottom: 50px;
    /* background-size: cover; */
`;


export default function RwLocations() {

    return (
        <Container>
           <SectionHead>
            <SubHeader>LOCATIONS & OPEN HOURS</SubHeader>
           </SectionHead>
           <LocationInfo>
                <AddressWrap>
                    <AddressText>
                        1329 Cherry Way Dr, Suite 205 Gahanna, OH 43230
                    </AddressText>
                </AddressWrap>
                <Time>
                    <Group>
                        <Field>MONDAYS - FRIDAYS</Field>
                        <Value>11:00 AM - 7:00 PM</Value>
                    </Group>
                    <Group>
                        <Field>SATURDAYS</Field>
                        <Value>11:00 AM - 6:00 PM</Value>
                    </Group>
                    <Group>
                        <Field>SUNDAYS</Field>
                        <Value>CLOSED</Value>
                    </Group>
                </Time>
           </LocationInfo>
           <Map>
           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3053.95703256699!2d-82.8870537!3d40.0540598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883861d643da55ed%3A0x735446c9b460faaf!2sRe-Hydrate%20Wellness%20IV%20Hydration!5e0!3m2!1sen!2sng!4v1657851141679!5m2!1sen!2sng" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </Map>
        </Container>
    );
}
