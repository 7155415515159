import styled from 'styled-components';
import { mobile } from '../responsive';
import Icon1 from '../assets/images/benefit_icon1.png';
import Icon2 from '../assets/images/benefit_icon2.png';
import Icon3 from '../assets/images/benefit_icon3.png';
import Icon4 from '../assets/images/benefit_icon4.png';
import { Link } from "react-router-dom";



const Container = styled.div`
    width: 100%;
    padding: 50px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;
const SectionHead = styled.div`
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const SectionHeader = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const BenefitsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const BWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;
const BItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 15px;
    width: 25%;
    margin-bottom: 20px;
    ${mobile({ 
        width: '100%',
        marginBottom: '40px'
    })}
`;
const BIcon = styled.img`
    width: 40px;
    margin-bottom: 15px;
`;
const BTitle = styled.div`
    text-align: center;
    margin-bottom: 8px;
    color: #323232;
    font-size: 16px;
    font-weight: 600;
`;
const BDesc = styled.div`
    text-align: center;
    margin-bottom: 10px;
    color: #323232;
    font-size: 14px;
    font-weight: 300;

`;



export default function RwHomeAbout() {


    return (
        <Container>
            <SectionHead>
                <SubHeader>Benefits of IV hydration</SubHeader>
                <SectionHeader>Benefits of IV hydration</SectionHeader>
            </SectionHead>

            <BenefitsContainer>
                <BWrap>
                    <BItem>
                        <BIcon src={Icon1}/>
                        <BTitle>Instant Hydration</BTitle>
                        <BDesc>
                            Fast and efficient total body hydration to restore balance with nutrients ○ 
                            and electrolytes.
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon src={Icon2}/>
                        <BTitle>Improve Wellness</BTitle>
                        <BDesc>
                            Detoxify and improve the functions of vital body organs.
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon src={Icon3}/>
                        <BTitle>Boost Energy</BTitle>
                        <BDesc>
                            Increase energy levels to get through the day or power through 
                            an intense workout.
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon src={Icon4}/>
                        <BTitle>Prevent Illness</BTitle>
                        <BDesc>
                            Avoid sickness and speed up healing with immunity boosting vitamins
                        </BDesc>
                    </BItem>
                </BWrap>
                {/* <BWrap>
                    <BItem>
                        <BIcon />
                        <BTitle>Anti-Aging</BTitle>
                        <BDesc>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon />
                        <BTitle>Anti-Aging</BTitle>
                        <BDesc>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon />
                        <BTitle>Anti-Aging</BTitle>
                        <BDesc>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt
                        </BDesc>
                    </BItem>
                    <BItem>
                        <BIcon />
                        <BTitle>Anti-Aging</BTitle>
                        <BDesc>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt
                        </BDesc>
                    </BItem>
                </BWrap> */}
            </BenefitsContainer>
        </Container>
    );
}
