import ProductImg1 from './assets/images/iv_bag2.jpg';
import ProductImg2 from './assets/images/peel22.png';
import DropImg from './assets/images/drop2.jpg';
import ShotImg from './assets/images/shots2.jpg';
import BoiReelImg from './assets/images/BioRePeel_C13.jpg';
import ProImg from './assets/images/ProClinical2.png';

export const serviceList = [
    {
      id: 1,
      name: "Hydration",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Fluids and electrolytes formulated to have you feeling revitalized and refreshed",
      price: 125,
      url: "",
      ingredients: "Saline, Potassium, Calcium"
    },
    {
      id: 2,
      name: "Party",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Hangover drip to detoxify your body pre and post party. Helps to get rid of hangover symptoms",
      price: 125,
      url: "",
      ingredients: "B-Complex, Mineral Blend, Zofran"
    },
    {
      id: 3,
      name: "Energy",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Helps boost your energy levels and improves your overall health & wellness",
      price: 125,
      url: "",
      ingredients: "Vitamin C, B-Complex, Mineral Blend"
    },
    {
      id: 4,
      name: "Slim",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Vitamins and amino acids to help boost metabolism, burn fat and detox the body",
      price: 159,
      url: "",
      ingredients: "Amino Blend, B-Complex"
    },
    {
      id: 5,
      name: "Immunity",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Packed with vitamins and antioxidants for fast healing and supercharge your immune system",
      price: 159,
      url: "",
      ingredients: "Vitamin C, B-Complex, Zinc"
    },
    {
      id: 6,
      name: "Myers Cocktail",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Helps alleviate stress, improve immunity, restore balance to the body, reduce migraines and chronic pain",
      price: 159,
      url: "",
      ingredients: "Magnesium, Calcium, B Vitamins + C"
    },
    {
      id: 7,
      name: "libido drip",
      img: ProductImg1,
      category: "ivdrips",
      desc: "His & Hers. Increase sexual drive while improving energy for better and longer performance",
      price: 159,
      url: "",
      ingredients: "Amino Blend, Arginine, Taurine"
    },
    {
      id: 8,
      name: "Allergy",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Full of anti-inflammatory agents, antioxidants & histamine blocker to knock out allergens",
      price: 189,
      url: "",
      ingredients: "Myers, Vit C, Glutathione, Pepcid"
    },
    {
      id: 9,
      name: "Beauty",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Skin rejuvenation inside and out. Fight acne wrinkles and tired skin with vitamins and antioxidants",
      price: 189,
      url: "",
      ingredients: "Biotin, Vitamin C, B-Complex"
    },
    {
      id: 10,
      name: "Fitness",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Decrease recovery time and enhance athletic performance, while reducing inflammation",
      price: 189,
      url: "",
      ingredients: "B-Complex, Carnitine, Arginine, Mg"
    },
    {
      id: 11,
      name: "Ultimate",
      img: ProductImg1,
      category: "ivdrips",
      desc: "Double the hydration with our most comprehensive IV drip customized with vitamins, amino acids and boosters to meet your health and wellness needs. Includes an additional bag of hydration",
      price: 199,
      url: "",
      ingredients: "Varies"
    },
    {
      id: 12,
      name: "Glutathione",
      img: DropImg,
      category: "boosters",
      desc: "Detoxify, Anti-aging, Cell regeneration and Improved cognitive function ",
      price: 59,
      url: "",
      ingredients: ""
    },
    {
      id: 13,
      name: "Extra hydration",
      img: DropImg,
      category: "boosters",
      desc: "500 ml bag of NS (Normal Saline)",
      price: 25,
      url: "",
      ingredients: ""
    },
    {
      id: 14,
      name: "Amino Blend",
      img: DropImg,
      category: "boosters",
      desc: "Boost metabolism, improve energy levels, increase endurance and help burn fat",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 15,
      name: "Zinc",
      img: DropImg,
      category: "boosters",
      desc: "Aid metabolism, boost immune system, accelerate wound healing, decrease inflammation, help with acne treatment",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 16,
      name: "Vitamin B12",
      img: DropImg,
      category: "boosters",
      desc: "Aid in cell growth and development, boost energy level. Improve feelings of well-being, regulate mood, decrease anxiety and depression.",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 17,
      name: "Vitamin C",
      img: ShotImg,
      category: "boosters",
      desc: "Immunity, Anti-oxidant and Detoxification",
      price: 39,
      url: "",
      ingredients: ""
    },
    {
      id: 18,
      name: "Taurine",
      img: DropImg,
      category: "boosters",
      desc: "Mental Health, and Anti-oxidant",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 19,
      name: "Pepcid",
      img: DropImg,
      category: "boosters",
      desc: "Anti-histamine for Allergens",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 20,
      name: "Toradol",
      img: DropImg,
      category: "boosters",
      desc: "Relives pain and Reduces Inflammation",
      price: 45,
      url: "",
      ingredients: ""
    },
    {
      id: 21,
      name: "Glutathione",
      img: DropImg,
      category: "boosters",
      desc: "Powerful antioxidant with antiaging benefits for a natural skin glow; and detoxify the body",
      price: 45,
      url: "",
      ingredients: "Glutathione"
    },
    {
      id: 22,
      name: "Amino Blend",
      img: DropImg,
      category: "boosters",
      desc: "Boost your metabolism, improve energy levels, increase endurance and burn fat",
      price: 39,
      url: "",
      ingredients: "Essential and Non Essential Amino Acids"
    },
    {
      id: 23,
      name: "Immunity",
      img: ShotImg,
      category: "shots",
      desc: "A powerful blend for the ultimate immune boosting essentials for Cold/Flu Symptoms and Allergies",
      price: 39,
      url: "",
      ingredients: "Glutathione, Vitamin C and Zinc"
    },
    {
      id: 24,
      name: "Energy",
      img: ShotImg,
      category: "shots",
      desc: "Boost energy, improve mood and cognition, fight heart disease and improve digestion.",
      price: 39,
      url: "",
      ingredients: "Vitamin B12"
    },
    {
      id: 25,
      name: "Calm",
      img: ShotImg,
      category: "shots",
      desc: "Reduce Stress, Anxiety and Calms the brain",
      price: 39,
      url: "",
      ingredients: "Taurine"
    },        
    {
      id: 26,
      name: "Biotin",
      img: ShotImg,
      category: "shots",
      desc: "Promote healthy hair, skin and nails.",
      price: 39,
      url: "",
      ingredients: "Vitamin B7"
    },
    {
      id: 27,
      name: "libido",
      img: ShotImg,
      category: "shots",
      desc: "Boost Sexual Drive and Energy",
      price: 39,
      url: "",
      ingredients: "Amino Blend, Arginine, Taurine"
    },   
    {
      id: 28,
      name: "Vitamin D3",
      img: ShotImg,
      category: "shots",
      desc: "Help bone formation and calcium metabolism, helps in reducing the risk of cardiovascular incidences (stroke, heart attack, high blood pressure, high cholesterol)",
      price: 39,
      url: "",
      ingredients: "Vitamin D3"
    },    
    {
      id: 29,
      name: "Shot",
      img: ShotImg,
      category: "shots",
      desc: "Fat Burner that helps breakdown fat and Boost Metabolism with Vitamins and Amino Acid Blend",
      price: 39,
      url: "",
      ingredients: "Lipo Mix (Vitamins and Amino Acid Blend)"
    }, 
    {
      id: 30,
      name: "The Perfect Derma Peel",
      img: ProductImg2,
      category: "chemical_peels",
      desc: "The Perfect Derma Peel is a safe, effective medium depth peel for All Skin Types & Ethnicity.  This product is virtually painless, with no pre-peel skin preparation and little downtime. The Perfect Derma Peel is the only peel that includes the powerful anti-oxidant Glutathione, which lightens and brightens the skin, slows down the aging process and helps prevent wrinkles. It can be applied every 4 weeks.",
      price: 245,
      url: "",
      ingredients: ""
    },
    {
      id: 31,
      name: "Pro Clinical Peel",
      img: ProImg,
      category: "chemical_peels",
      desc: "Pro Clinical Peel features a powerful, clinically proven blend of acids & antioxidants for a fresh, more youthful appearance. Pro Clinical Peel can be applied every 2 weeks, or as recommended by our skin care professional. It also helps to maintains results from your Perfect Derma Peel between applications, Reduces fine lines & wrinkles, Evens out skin tone and improves texture, Minimizes the appearance of pores, Brightens and refreshes the complexion",
      price: 160,
      url: "",
      ingredients: ""
    },
    {
      id: 32,
      name: "BioRePeel C13",
      img: BoiReelImg,
      category: "chemical_peels",
      desc: "BioRePeel C13 helps fight the effects of skin aging by reducing the appearance of wrinkles and fine lines on the face, neck, and chest area. It is also helpful for people with younger skin who are battling acne and may have scarring and blackheads. This gentle No-Peel chemical peel is an innovative formula that has 14 ingredients that remove the outer layer of skin which shows signs of damage from the sun, pollution and effects of aging, as well as stimulating the replication of healthy new skin cells to ensure the clearest, most youthful skin. BioRePeel C13 gently exfoliates the outer layer of skin, stimulates cell regeneration, and also nourishes the skin with vitamin C and B2, and four amino acids, leaving your skin looking and feeling fresh and new. BioRePeelCl3 can also be used on the body to achieve the following results: softer, clearer skin, reduction/elimination of back and acne on the chest, reduction of hyperpigmentation, smoother lines above the knees, more moisturized skin.",
      price: 570,
      url: "",
      ingredients: ""
    },
   
  ];

  export const staffProfiles = [
    {
      id: 0,
      name: "Jennifer Anderson",
      img: "",
      profession: "Nurse",
      bio: "Lorem ipsum dolor sit amet, consectetur elit adipiscing elit, sed do eiusmod tempor sunt officia deserunt mollit anim id est laborum",
    },

    {
      id: 1,
      name: "nneka ada",
      img:"",
      profession: "Biologist",
      bio: "Lorem ipsum dolor sit amet, consectetur elit adipiscing elit, sed do eiusmod tempor sunt officia deserunt mollit anim id est laborum",
    },
   
  ];

  export const testimonials = [
    {
      id: 0,
      name: "Taylor N",
      img: "",
      testimony: "I loved this experience! The nurse was extremely accommodating for me. She had something emergent come up outside of work and needed to leave, but chose to wait until I got there and finish my IV before leaving and taking care of it, which she absolutely did not have to do :) I felt refreshed and hydrated after the infusion, I will definitely be back!",
    },
    {
      id: 1,
      name: "Emily Morgan",
      img: "",
      testimony: "Came here for the first time today not knowing what to expect. I have been not feeling well and decided to give IV therapy a try. Alma was so friendly when I arrived and helped pick what was best for me based on my symptoms. Alma was so kind and I really enjoyed my experience. I am looking forward to when I can come back!",
    },
    {
      id: 2,
      name: "3ns Productions",
      img: "",
      testimony: "Re-Hydrate Wellness is an absolute must visit. I got an energy drip, to boost my energy and felt very energized. Alma was very knowledgeable and experienced in starting my IV with ease. I highly recommend to check this place for your health and Wellness.",
    },
    {
      id: 3,
      name: "Khalil Surakata Kuyateh",
      img: "",
      testimony: "Let's say I was not sick but not feeling like my usual self (energetic & active ) but after getting the IV rehydration ! Wow I feel better active , was up the next day n almost all night. Waking up early, feeling like I'm 18 again. It's amazing and I'm personally recommending to anyone and everyone .",
    },
    {
      id: 4,
      name: "Bin Bin",
      img: "",
      testimony: "Re Hydrate Wellness is at the top of the notch from when you enter the office the ambiance is great. The professional and care provided as you are attended to is great. I liked it all not forgetting the products offered B12, C and others that I have received from the center. ❤️Try it.",
    },
  
  ];

