import RwMainNav from '../components/navigation/RwMainNav';
import { Outlet } from "react-router-dom";
import RwFooter from '../components/navigation/RwFooter';

export default function MainApp() {


    return (
        <div>
            <RwMainNav />
            <Outlet/>
            <RwFooter />
        </div>
    );
}
