import styled from 'styled-components';
import { mobile } from '../responsive';
import AboutImg from '../assets/images/team_b2.jpg';
import { useEffect } from 'react';
import Testimonial from '../components/Testimonial';

import Icon1 from '../assets/images/icons/drop.png';
import Icon2 from '../assets/images/icons/calendar.png';
import Icon3 from '../assets/images/icons/doc.png';
import Icon4 from '../assets/images/icons/house.png';

const Container = styled.div`
    width: 100%;
    padding: 130px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ImageContainer = styled.div`
    width: 90%;
    margin-bottom: 20px;
`;
const Image = styled.img`
    width: 100%;
`;

const SectionHead = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const SectionHeader = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const TextContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
    ${mobile({ 
        width: "90%"
    })}
`;
const TextCol = styled.div`
    width: 49%;
    ${mobile({ 
        width: "100%"
    })}
`;
const TextParagraph = styled.div`
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
`;
const OurFounders = styled.div`
    width: 100%;
    color: #de7bb1;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: center;
`;
const GetStarted = styled.div`
    width: 100%;
    background-color: #fdecf6;
    padding: 70px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Header = styled.div`
    color: #212121;
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    text-transform: capitalize;
    ${mobile({ 
        fontSize: "28px",
    })}
`;
const InfoGraph = styled.div`
    width: 90%;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
`;
const Item = styled.div`
    width: 50%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mobile({ 
        width: "100%"
    })}

`;
const Icon = styled.img`
    width: 80px;
    margin-bottom: 15px;
`;
const Desc = styled.div`
    text-align: center;
    width: 250px;
    color: #212121;
    font-size: 20px;
    font-weight: 600;
`;

const TestimonialWrap = styled.div`
    width: 90%;
    ${mobile({ 
        width: "100%",
    })}
`;
export default function RwAbout() {

    useEffect(() => {
        document.title = "About Us | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <ImageContainer>
                <Image src={AboutImg}/>
            </ImageContainer>
            <SectionHead>
                <SubHeader>About Re-hydratewellness</SubHeader>
                <SectionHeader>About Us</SectionHeader>
            </SectionHead>
            <TextContainer>
                <TextParagraph>
                    We are passionate about integrated health and wellness, while promoting optimum wellbeing from within
                    through hydration. Our passion fuels our drive as wellness advocates for our community, with a strong
                    conviction that wellness begins with a body in balance.
                </TextParagraph>
                <TextParagraph>
                    IV hydration therapy not only rejuvenates, and replenishes the mind and body, it also helps to boost immunity.
                </TextParagraph>
                <TextParagraph>
                    We take pride in providing excellent patient care, a true testament of our dedication to health and wellness
                </TextParagraph>
                <TextParagraph>
                    Founded by two incredible Nurses. Florence Ayodele - BSN, RN (Nurse Flo) and Alma Regeru - MSN, APRN, FNP-BC who bring decades of combined clinical and IV hydration experience to the practice.
                </TextParagraph>
                <OurFounders>Our founders</OurFounders>
                <TextParagraph>
                    Founded by two incredible Nurses. Florence Ayodele - BSN, RN (Nurse Flo)
                    and Alma Regeru - MSN, APRN, FNP-BC who bring decades of combined
                    clinical and IV hydration experience to the practice.
                </TextParagraph>
            </TextContainer>
            <GetStarted>
                <Header>Get started</Header>
                <InfoGraph>
                    <Item>
                        <Icon src={Icon1}/>
                        <Desc>1. Select a drip just right for your body and mind</Desc>
                    </Item>
                    <Item>
                        <Icon src={Icon2}/>
                        <Desc>2. Schedule your drippin appointment</Desc>
                    </Item>
                    <Item>
                        <Icon src={Icon3}/>
                        <Desc>3. Recieve our practitioner’s medical clearance and we will match a nurse to you</Desc>
                    </Item>
                    <Item>
                        <Icon src={Icon4}/>
                        <Desc>4. Enjoy your drip at our drip lounge or at your home</Desc>
                    </Item>
                </InfoGraph>
            </GetStarted>
            <TestimonialWrap>
                <Testimonial />
            </TestimonialWrap>
        </Container>
    );
}
