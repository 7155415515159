import styled from 'styled-components';
import { mobile } from '../responsive';
import Locations from '../components/RwLocations';
import { useEffect } from 'react';



const Container = styled.div`
    width: 100%;
`;
const RowA = styled.div`
    width: 100%;
    padding: 130px 30px 30px 30px;
    display: flex;
    justify-content: center;
    background-color: #fae4e6;
    ${mobile({ 
        flexDirection: "column",
    })}
`;
const ColA = styled.div`
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        marginRight: "0",
        marginBottom: "70px"
    })}
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const ColB = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        width: "100%",
    })}
`;
const Line = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    ${mobile({ 
        flexDirection: "column",
        justifyContent: "center"
    })}
`;
const Group = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        width: "100%",
        textAlign: "center",
        marginBottom: "30px"
    })}
    
`;
const Field = styled.div`
    color: #323232;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
`;
const Value = styled.div`
    color: #323232;
    font-size: 16px;
    font-weight: 600;
`;
const RowB = styled.div`

`;

export default function RwContact() {

    useEffect(() => {
        document.title = "Contact Us | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);



    return (
        <Container>
            <RowA>
                <ColA>
                    <SubHeader>Contact Us</SubHeader>
                    <Header>Get In</Header>
                    <Header>Touch</Header>
                </ColA>
                <ColB>
                    <Line>
                        <Group>
                            <Field>EMAIL</Field>
                            <Value>info@rehydratewellness.com</Value>
                        </Group>
                        <Group>
                            <Field>PHONE</Field>
                            <Value>+1 614 532 1511</Value>
                        </Group>
                    </Line>
                    <Line>
                        <Group>
                            <Field>ADDRESS</Field>
                            <Value>1329 Cherry Way Dr, Suite 205 Gahanna, OH 43230</Value>
                        </Group>
                    </Line>
                </ColB>
            </RowA>
            <RowB>
                <Locations />
            </RowB>
        </Container>
    );
}
