import styled from 'styled-components';
import { mobile } from '../responsive';
import TheTeam from '../components/RwTheTeam';
import { useEffect } from 'react';
import NadImg1 from '../assets/images/nad1.jpg';
import NadImg2 from '../assets/images/nad2.jpg';
import NadImg from '../assets/images/nad.jpg';

const Container = styled.div`
    width: 100%;
    padding: 130px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Top = styled.div`
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    ${mobile({ 
        flexDirection: "column",
        padding: "0px 15px"
    })}
`;
const TopColA = styled.div`
    width: 30%;
    margin-right: 30px;
    ${mobile({ 
        width: "100%"
    })}
`;
const TopColB = styled.div`
    width: 40%;
    ${mobile({ 
        width: "100%"
    })}
`;
const TopImageWrap = styled.div`
    width: 100%;
`;
const TopImage = styled.img`
    width: 100%;
`;
const TopSubHead = styled.div`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const TopTitle = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const TopDesc = styled.div`
    color: #323232;
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const Pricing = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
`;
const PriceItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const ItemWrap = styled.div`
     display: flex;
`;
const Product = styled.div`
    width: 300px;
    color: #de7bb1;
    font-size: 18px;
    font-weight: 600;
`;
const Price = styled.div`
    color: #323232;
    font-size: 20px;
    font-weight: 600;
`;
const ProductDesc = styled.div`
    color: #323232;
    font-size: 16px;
    font-weight: 300;
`;
const Benefits = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        alignItems: "center",
        marginBottom: "45px",
        marginTop: "25px"
    })}
`;
const BenefitsTitle = styled.div`
    color: #323232;
    font-size: 18px;
    font-weight: 600;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const BenefitsWrap = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    ${mobile({ 
        marginTop: "15px",
        flexDirection: "column",
        alignItems: "center"
    })}
`;
const BLeft = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        width: "100%",
        alignItems: "center"
    })}
`;
const BRight = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    })}
`;
const BookItem = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    ${mobile({ 
        justifyContent: "center"
    })}
`;
const Icon = styled.span`
    width: 3px;
    height: 3px;
    background-color: #de7bb1;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
`;
const ItemText = styled.span`
    color: #323232;
    font-size: 16px;
    font-weight: 300;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const TopCtaWrap = styled.div`
    display: flex;
    margin-top: 20px;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center"
    })}
`;
const Bottom = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    padding: 50px 30px;
    box-sizing: border-box;
    background-color: #fae4e6;
    flex-direction: column;
    align-items: center;
`;
const SectionHead = styled.div`
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SubHeader = styled.h3`
    color: #323232;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const SectionHeader = styled.div`
    color: #de7bb1;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    ${mobile({ 
        flexDirection: "column"
    })}
`;
const ColA = styled.div`
    width: 45%;
    height: min-content;
    background-color: #8a8d8d;
    margin-right: ${props=> props.rightMargin === true && "30px"};
    ${mobile({ 
        width: "100%",
        marginRight: "0px",
        marginTop: "20px"
    })}
`;
const Image = styled.img`
    width: 100%;
`;

const ColB = styled.div`
    width: 45%;
    height: min-content;
    margin-top: 20px;
    margin-right: ${props=> props.rightMargin === true && "30px"};
    ${mobile({ 
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "0px"
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1.2;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const Desc = styled.div`
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Cta = styled.div`
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    margin-right: 20px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
    ${mobile({ 
        marginBottom: "15px",
        padding: "15px 45px"
    })}
`;
const CtaText = styled.div`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    white-space: nowrap;
    text-transform: uppercase;
`;
export default function RwNad() {

    useEffect(() => {
        document.title = "Concierge | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Top>
                <TopColA>
                    <TopImageWrap>
                        <TopImage src={NadImg}/>
                    </TopImageWrap>
                </TopColA>
                <TopColB>
                    <TopSubHead>Ultimate Wellness</TopSubHead>
                    <TopTitle>NAD Infusion</TopTitle>
                    <TopDesc>
                        NAD+ can help the body to function better on a molecular level and can help
                        overcome anxiety depression and addiction
                    </TopDesc>
                    <Pricing>
                        <PriceItem>
                            <ItemWrap>
                                <Product>NAD+ 500 mg</Product>
                                <Price>$600</Price>
                            </ItemWrap>
                        </PriceItem>
                        <PriceItem>
                            <ItemWrap>
                                <Product>NAD+ 250 mg</Product>
                                <Price>$300</Price>
                            </ItemWrap>
                        </PriceItem>
                        <PriceItem>
                            <ItemWrap>
                                <Product>IV Push NAD+ 100 mg</Product>
                                <Price>$99</Price>
                            </ItemWrap>
                            <ProductDesc>
                                Anti-aging, promotes healthy brain function and Increased energy
                            </ProductDesc>
                        </PriceItem>
                    </Pricing>
                    <Benefits>
                        <BenefitsTitle>Potential Benefits</BenefitsTitle>
                        <BenefitsWrap>
                            <BLeft>
                                <BookItem>
                                    <Icon />
                                    <ItemText>
                                        Detoxify Your Cells
                                    </ItemText>
                                </BookItem>
                                <BookItem>
                                    <Icon />
                                    <ItemText>
                                        Boost Energy
                                    </ItemText>
                                </BookItem>
                                <BookItem>
                                    <Icon />
                                    <ItemText>
                                        Improves Health
                                    </ItemText>
                                </BookItem>
                            </BLeft>
                            <BRight>
                                <BookItem>
                                    <Icon />
                                    <ItemText>
                                        Optimize Brain Function
                                    </ItemText>
                                </BookItem>
                                <BookItem>
                                    <Icon />
                                    <ItemText>
                                        Reducing Cell Damage
                                    </ItemText>
                                </BookItem>
                            </BRight>
                        </BenefitsWrap>
                    </Benefits>
                    <TopCtaWrap>
                        <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                            <Cta>
                                <CtaText>Book Now</CtaText>
                            </Cta>
                        </a>
                        {/* <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                            <Cta>
                                <CtaText>Schedule An Appointment</CtaText>
                            </Cta>
                        </a> */}
                    </TopCtaWrap>
                </TopColB>
            </Top>
            <Bottom>
                <SectionHead>
                    <SubHeader>NAD Infusion</SubHeader>
                    <SectionHeader>About NAD Infusion</SectionHeader>
                </SectionHead>
                <Row>
                    <ColA rightMargin={true}>
                        <Image src={NadImg1}/>
                    </ColA>
                    <ColB rightMargin={false}>
                        <Header>What is NAD?</Header>
                        <Desc>
                            NAD, or nicotinamide adenine dinucleotide, is a naturally occurring co-enzyme
                            of niacin that helps cells in our bodies produce energy. It does so by converting the
                            energy we get from food into cellular energy.
                        </Desc>
                    </ColB>
                </Row>
                <Row>
                    <ColB rightMargin={true}>
                        <Header>How does NAD+</Header>
                        <Header>therapy work?</Header>
                        <Desc>
                            In NAD Therapy, the co-enzyme is placed in an IV and slowly dripped into the blood
                            stream. This allows the substance to bypass the stomach and travel directly to the
                            brain. This provides the individual with a boost of energy, providing enhanced mood
                            and awareness. It is also helpful in slowing down the aging process.
                            The excessive use of drugs and alcohol will deplete the body’s natural stores of NAD.
                            As a result, the brain cannot receive the same energy it usually would from breaking
                            down food. NAD therapy floods the brain with the co-enzyme to replenish its stores.
                        </Desc>
                    </ColB>
                    <ColA rightMargin={false}>
                        <Image src={NadImg2}/>
                    </ColA>
                </Row>

            </Bottom>

        </Container>
    );
}
