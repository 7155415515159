import styled from 'styled-components';
import { mobile } from '../responsive';
// import AboutImg from '../assets/images/peel.jpg';
import { Add, ClearOutlined } from "@material-ui/icons";
import { useState, useEffect } from 'react';
import { staffProfiles } from '../data';


const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    ${mobile({ 
        flexDirection: "column",
        paddingTop: "30px"
    })}
`;
const ColA = styled.div`
    width: min-content;
    height: min-content;
    margin-right: 10%;
    ${mobile({ 
        width: "100%",
        marginright: "0px",
        marginBottom: "40px"
    })}
`;

const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;



const ColB = styled.div`
    width: 50%;
    height: min-content;
    ${mobile({ 
         width: "100%",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center"
    })}
`;
const Profile  = styled.div`
    width: 47%;
    margin-right: ${props=> props.rightMargin === true && "6%"};
    margin-bottom: 40px;
    float: left;
`;
const ImageWrap  = styled.div`
    width: 100%;
    position: relative;
`;
const Image  = styled.div`
    width: 100%;
`;
const Overlay  = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #00000090;
    z-index: 5;
    opacity: 0;
    transition: all 0.5s ease;
    &:hover {
        opacity: 1;
    }
`;
const Btn  = styled.div`
 display: flex;
 justify-content: center;
 padding: 10px;
 width: 80%;
 background-color: #fae4e6;
 height: min-content;
 overflow-wrap: break-word;
 cursor: pointer;
`;
const BtnText  = styled.span`
    color: #de7bb1;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    white-space: nowrap;
`;
const Name  = styled.div`
    color: #323232;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
`;
const Desc  = styled.div`
    color: #323232;
    font-size: 16px;
    font-weight: 300;
`;

const StaffProfileOverlay = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #00000090;
    z-index: 20;
    transition: all 0.2s ease;
    ${({ dragging }) => dragging ? 'display: flex;' : 'display: none;'}
    align-items: center;
    justify-content: center;
    ${mobile({ 
        height: "min-content",
    })}
`;
const StaffProfile = styled.div`
    width: 90%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
`;
const OVProfile = styled.div`
    width: 100%;
    display: flex;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
    ${mobile({ 
        flexDirection: "column",
    })}
`;
const OVImageWrap = styled.div`
    width: 30%;
    margin-right: 30px;
    ${mobile({ 
        width: "100%",
    })}
`;
const OVImage = styled.img`
     width: 100%;
`;
const OVStaff = styled.div`
    width: 50%;
    ${mobile({ 
        width: "100%",
    })}
`;
const OVName = styled.div`
    color: #323232;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
`;
const OVDesc = styled.div`
    color: #323232;
    font-size: 18px;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 20px;
`;
const OVBio = styled.div`
    color: #323232;
    font-size: 16px;
`;

export default function RwTheTeam() {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [profileIndex, setProfileIndex] = useState(0);

    const openProfile = (id) => {
        setProfileIndex(id);
        setIsProfileOpen(true);
      };

    return (
        <Container>
            <ColA>
                <SubHeader>Our Team</SubHeader>
                <Header>Meet</Header>
                <Header>The</Header>
                <Header>Team</Header>
            </ColA>
            <ColB>
                <Profile rightMargin={true}>
                    <ImageWrap>
                        <Image />
                        <Overlay>
                            <Btn onClick={()=>openProfile(0)}>
                                <BtnText>View Profile</BtnText>
                                <Add  style={{ color: '#de7bb1', fontSize: 21 }} />
                            </Btn>
                        </Overlay>
                    </ImageWrap>
                    <Name>Theodora Ibeh</Name>
                    <Desc>Nurse</Desc>              
                </Profile>
                <Profile rightMargin={false}>
                    <ImageWrap>
                        <Image />
                        <Overlay>
                            <Btn onClick={()=>openProfile(1)}>
                                <BtnText>View Profile</BtnText>
                                <Add  style={{ color: '#de7bb1', fontSize: 21 }} />
                            </Btn>
                        </Overlay>
                    </ImageWrap>
                    <Name>Theodora Ibeh</Name>
                    <Desc>Nurse</Desc>              
                </Profile>
                <Profile rightMargin={true}>
                    <ImageWrap>
                        <Image />
                        <Overlay>
                            <Btn onClick={()=>openProfile(1)}>
                                <BtnText>View Profile</BtnText>
                                <Add  style={{ color: '#de7bb1', fontSize: 21 }} />
                            </Btn>
                        </Overlay>
                    </ImageWrap>
                    <Name>Theodora Ibeh</Name>
                    <Desc>Nurse</Desc>              
                </Profile>
                <Profile rightMargin={false}>
                    <ImageWrap>
                        <Image />
                        <Overlay>
                            <Btn onClick={()=>openProfile(1)}>
                                <BtnText>View Profile</BtnText>
                                <Add  style={{ color: '#de7bb1', fontSize: 21 }} />
                            </Btn>
                        </Overlay>
                    </ImageWrap>
                    <Name>Theodora Ibeh</Name>
                    <Desc>Nurse</Desc>              
                </Profile>
            </ColB>
            <StaffProfileOverlay  dragging={isProfileOpen}>
                <StaffProfile>
                    <ClearOutlined 
                        style={{ 
                            color: '#323232', 
                            fontSize: 28,
                            position: 'absolute',
                            top: 30,
                            right: 30,
                            cursor: 'pointer'
                        }}
                        onClick={()=>setIsProfileOpen(false)}
                    />
                    <OVProfile>
                        <OVImageWrap>
                            <OVImage src={staffProfiles[profileIndex].img}/>
                        </OVImageWrap>
                        <OVStaff>
                            <OVName>{staffProfiles[profileIndex].name}</OVName>
                            <OVDesc>{staffProfiles[profileIndex].profession}</OVDesc>
                            <OVBio>
                                {staffProfiles[profileIndex].bio}
                            </OVBio>
                        </OVStaff>
                    </OVProfile>

                </StaffProfile>
            </StaffProfileOverlay>
        </Container>
    );
}
