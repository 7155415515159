import styled from 'styled-components';
import { mobile } from '../responsive';
import BgImg1 from '../assets/images/van_bg4.jpg';
import Icon1 from '../assets/images/icons/chat.png';
import Icon2 from '../assets/images/icons/phone.png';
import Icon3 from '../assets/images/icons/walk.png';
import Icon4 from '../assets/images/icons/message.png';
import { useEffect } from 'react';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const RowA = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    background-color: #fae4e6;
    flex-direction: column;
    align-items: center;
    ${mobile({ 

    })}
`;
const ColA = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    ${mobile({ 

    })}
`;
const InfoWrap = styled.div`
    width: 350px;
    position: relative;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 30px;
    overflow-wrap: break-word;
    background-color: #ffffff50;
    ${mobile({ 
        left: "calc(50% - 175px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    })}
`;
const HeaderWrap = styled.div`
`;
const HeaderText = styled.h1`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 0.9;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const MDesc = styled.span`
    color: #de7bb1;
    font-size: 24px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const CTA = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
    ${mobile({ 
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    })}
`;
const CTAText = styled.span`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
`;

const RowB = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;
`;

const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    ${mobile({ 
        marginTop: "30px",
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    ${mobile({ 
        textAlign: "center"
    })}
`;

const CTA2 = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
`;
const RowC = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const GetStarted = styled.div`
    width: 100%;
    padding: 50px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const InfoGraph = styled.div`
    width: 65%;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
`;
const Item = styled.div`
    width: 50%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mobile({ 
        width: "100%"
    })}

`;
const Icon = styled.img`
    width: 50px;
    margin-bottom: 15px;
`;
const Title = styled.div`
    text-align: center;
    color: #212121;
    font-size: 20px;
    font-weight: 600;
`;
const Desc = styled.div`
    text-align: center;
    width: 250px;
    color: #212121;
    font-size: 16px;
    font-weight: 300;
`;


export default function RwConcierge() {

    useEffect(() => {
        document.title = "Memberships | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <RowA>
                <ColA style={{ backgroundImage: `url(${BgImg1})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <HeaderText>
                                We Come
                            </HeaderText>
                            <HeaderText>
                                To You
                            </HeaderText>
                        </HeaderWrap>
                        <MDesc>
                            At Home, In Office
                            or Hotel
                        </MDesc>
                    </InfoWrap>
                </ColA>               
            </RowA>
            <RowB>
                <SubHeader>Concierge</SubHeader>
                <Header>Booking options</Header>
            </RowB>
            <RowC>
                <GetStarted>
                    <InfoGraph>
                        <Item>
                            <Icon src={Icon1}/>
                            <Title>Chat with us</Title>
                            <Desc>
                                Type "Concierge" in the Chat box
                            </Desc>
                        </Item>
                        <Item>
                            <Icon src={Icon2}/>
                            <Title>Call us</Title>
                            <Desc>614 532 1511</Desc>
                        </Item>
                        <Item>
                            <Icon src={Icon3}/>
                            <Title>Walk in</Title>
                            <Desc>
                                1329 Cherry Way Dr, Suite 205 Gahanna, OH 43230
                            </Desc>
                        </Item>
                        <Item>
                            <Icon src={Icon4}/>
                            <Title>Social media</Title>
                            <Desc>
                                DM us on our social media platforms
                            </Desc>
                        </Item>
                    </InfoGraph>

                </GetStarted>
            </RowC>      
        </Container>
    );
}
