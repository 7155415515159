import styled, { keyframes } from 'styled-components';
import { mobile } from '../responsive';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import BgImg1 from '../assets/images/model6.jpg';
import BgImg2 from '../assets/images/van_bg.jpg';
import BgImg3 from '../assets/images/homebg3.jpg';
import BgMobImg1 from '../assets/images/mob_model.jpg';
import BgMobImg2 from '../assets/images/mob_bg2.jpg';
import BgMobImg3 from '../assets/images/mob_van_bg2.jpg';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;  
`;
const Slide = styled.div`
    width: 300%;
    height: 100%;
    position: relative;
    display: flex;
    transition: all 1s ease;
    left: ${(props) => props.slideIndex}%;
`;
const Col = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    ${mobile({ 
        padding: "0px 15px"
    })}
`;
const SlideIndicator = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    ${mobile({ 
        display: "none"
    })}
`;
const TopIndex = styled.span`
    font-weight: 600;
    font-size: 12px;
    color: #323232;
    display: block;
    margin-bottom: 14px;
`;
const BottomIndex = styled.span`
    font-weight: 600;
    font-size: 12px;
    color: #323232;
    display: block;
    margin-top: 14px;
`;
// const BarWrap = styled.div`
// `;
const Base = styled.div`
    position: relative;
    width: 2px;
    height: 120px;
    background-color: #323232;
`;
const Bar = styled.div`
    position: relative;
    width: 3px;
    height: 40px;
    background-color: #fff;
    transition: all 0.3s ease;
    top: ${props => props.slideIndex + "px"};
`;
const InfoWrap = styled.div`
    width: 350px;
    position: relative;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 30px;
    overflow-wrap: break-word;
    background-color: #ffffff50;
    ${mobile({ 
        width: "100%",
        // left: "calc(50% - 175px)",
        left: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    })}
`;
const HeaderWrap = styled.div`
`;
const Header = styled.h1`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 0.9;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Desc = styled.span`
    color: #666666;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const CTA = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
    ${mobile({ 
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    })}
`;
const CTAText = styled.span`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
`;


export default function RwHomeHero() {
    const [SlideIndex, setSlideIndex] = useState(0);
    const SlideIndexValues = [0, -100, -200];
    const [currentValue, setCurrentValue] = useState(SlideIndexValues[SlideIndex]);

    const SlideIndicatorValues = [0, 40, 80];

    const [SlideIndicatorIndex, setSlideIndicatorIndex] = useState(0);
    const [currentIndicatorValue, setCurrentIndicatorValue] = useState(SlideIndicatorValues[SlideIndicatorIndex]);

    const ViewPointWidth = window.innerWidth;

    useEffect(() => {
        setCurrentValue(SlideIndexValues[SlideIndex])
    }, [SlideIndex]);

    useEffect(() => {
        setCurrentIndicatorValue(SlideIndicatorValues[SlideIndicatorIndex])
    }, [SlideIndicatorIndex]);

    useEffect(() => {
        const interval = setTimeout(() => {
            setSlideIndex(SlideIndex ===  SlideIndexValues.length - 1 ? 0 : SlideIndex + 1);
            setSlideIndicatorIndex(SlideIndicatorIndex ===  SlideIndicatorValues.length - 1 ? 0 : SlideIndicatorIndex + 1);
        }, 3000);
    }, [currentValue]);

    return (
        <Container>
            <SlideIndicator>
                <TopIndex>01</TopIndex>
                        <Base>
                            <Bar slideIndex={currentIndicatorValue} />
                        </Base>
                <BottomIndex>03</BottomIndex>
            </SlideIndicator>
            <Slide slideIndex={currentValue}>
                <Col style={ ViewPointWidth > 728 ? { backgroundImage: `url(${BgImg1})`} : { backgroundImage: `url(${BgMobImg1})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <Header>
                                Refresh,
                            </Header>
                            <Header>
                                restore,
                            </Header>
                            <Header>
                                revitalize
                            </Header>
                        </HeaderWrap>
                        <Desc>
                            IV therapy is a unique way to support your health and wellness by receiving essential nutrients to boost your immune system
                        </Desc>
                        <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                            <CTA>
                                <CTAText>BOOK NOW</CTAText>
                            </CTA>
                        </a>
                    </InfoWrap>
                </Col>
                <Col style={ ViewPointWidth > 728 ? { backgroundImage: `url(${BgImg3})`} : { backgroundImage: `url(${BgMobImg2})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <Header>
                                IV Hydration
                            </Header>
                            <Header>
                                reimagined
                            </Header>
                        </HeaderWrap>
                        <Desc>
                            Improved Access, Plus our dedicated team of Nurse Practitioners and Nurses our commitment to your wellbeing is unparalleled
                        </Desc>
                        <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                            <CTA>
                                <CTAText>BOOK NOW</CTAText>
                            </CTA>
                        </a>
                    </InfoWrap>
                </Col>
                <Col style={ ViewPointWidth > 728 ? { backgroundImage: `url(${BgImg2})`} : { backgroundImage: `url(${BgMobImg3})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <Header>
                                IV Hydration at
                            </Header>
                            <Header>
                                your convenience
                            </Header>
                        </HeaderWrap>
                        <Desc>
                            We come to You. At Home, In Office or Hotel. book an appointment at your convenience
                        </Desc>
                        <Link to={`/concierge`} style={{ textDecoration: 'none' }}>
                            <CTA>
                                <CTAText>Learn More</CTAText>
                            </CTA>
                        </Link>
                    </InfoWrap>
                </Col>
            </Slide>
        </Container>
    );
}
