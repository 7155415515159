import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { mobile } from '../../responsive';
import LogoGray from '../../assets/images/logo_gray.png';
import {  Facebook, Instagram, Pinterest } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 50px 60px 10px 60px;
    background-color: #f5f3f3;
    ${mobile({ 
         padding: "60px 20px",
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
    })}
`;
const RowA = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    })}
`;
const ColA = styled.div`
    display: flex;
    width: 25%;
    flex-direction: column;
    ${mobile({ 
        width: "100%",
        marginBottom: "60px",
        alignItems: "center"
    })}
`;
const LogoWrap = styled.div`
    width: 100%;
    margin-bottom: 20px;
    ${mobile({ 
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    })}
`;
const Logo = styled.img`
    width: 150px;
`;
const Desc = styled.span`
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 300;
    display: block;
    margin-bottom: 30px;
    ${mobile({ 
        textAlign: "center",
        width: "80%"
    })}
`;
const SocialIcons = styled.div`
    display: flex;
    width: 100%;
    ${mobile({ 
        justifyContent: "center",
    })}
`;
const Icon = styled.div`
    margin-right: 10px;
`;

const ColB = styled.div`
    display: flex;
    width: min-content;
    flex-direction: column;
    ${mobile({ 
        marginBottom: "60px"
    })}
`;
const Header = styled.div`
    margin-bottom: 20px;
`;
const HeaderText = styled.h3`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const ItemWrap = styled.div`
     ${mobile({ 
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    })}
`;
const Item = styled.div`
    margin-bottom: 7px;
`;
const ItemText = styled.span`
    color: #777676;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 2px;
    white-space: nowrap;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        color: #de7bb1;
    }
`;

const RowC = styled.div`
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
    })}
`;
const RowB = styled.div`
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
    border-top: 1px solid #e6dfe2;
    margin-top: 20px;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
    })}
`;
const Copyright = styled.div`
    width: min-content;
`;
const CText = styled.span`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
`;
const C2Text = styled.span`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    ${mobile({
        textAlign: "center",
    })}
`;
const DAttribute = styled.div`
    width: min-content;
`;
const DText = styled.span`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        color: #de7bb1;
    }
`;
const useStyles = makeStyles({
    socialIcons: {
        color: '#323232', 
        fontSize: 18, 
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            color: "#de7bb1",
            scale: 1.5
         },
    },
  });
export default function RwHomeFooter() {
    const classes = useStyles();

    return (
        <Container>           
            <RowA>
                <ColA>
                    <LogoWrap>
                        <Logo src={LogoGray} />
                    </LogoWrap>
                    <Desc>
                        To integrate overall health and wellness, to promote and achieve optimum wellbeing 
                        from within while maintaining proper hydration, recovery and performance. We commit 
                        to provide our customers with a high level of customer service, medical expertise and 
                        dedication to their health and wellness.
                    </Desc>
                    <SocialIcons>
                            <Icon>
                                <a href="https://www.facebook.com/rehydratewellness" style={{ textDecoration: 'none' }}>
                                    <Facebook className={classes.socialIcons}/>
                                </a>
                            </Icon>
                        <Icon>
                            <a href="https://www.instagram.com/rehydratewellness" style={{ textDecoration: 'none' }}>
                                <Instagram className={classes.socialIcons}/>
                            </a>
                        </Icon>
                        <Icon>
                            <a href="https://www.pinterest.com/rehydratewellness" style={{ textDecoration: 'none' }}>
                                <Pinterest className={classes.socialIcons}/>
                            </a>
                        </Icon>
                    </SocialIcons>
                </ColA>

                <ColB>
                    <Header>
                        <HeaderText>Quick Links</HeaderText>
                    </Header>
                    <ItemWrap>
                        <Item>
                            <Link to={`/services`} style={{ textDecoration: 'none' }}>
                                <ItemText>Services</ItemText>
                            </Link>
                        </Item>
                        <Item>
                            <a href="https://rehydratewellness.square.site/products" style={{ textDecoration: 'none' }}>
                                <ItemText>Products</ItemText>
                            </a>
                        </Item>
                            <Link to={`/memberships`} style={{ textDecoration: 'none' }}>
                                <ItemText>Memberships</ItemText>
                            </Link>
                    </ItemWrap>
                </ColB>
                <ColB>
                    <Header>
                        <HeaderText>Know More</HeaderText>
                    </Header>
                    <ItemWrap>
                        <Item>
                            <Link to={`/faq`} style={{ textDecoration: 'none' }}>
                                <ItemText>FAQ</ItemText>
                            </Link>
                        </Item>
                        <Item>
                            <a href="https://app.termly.io/document/terms-of-use-for-website/8f730e22-8f93-4aa7-b1cd-67d46d61466e" style={{ textDecoration: 'none' }} >
                                <ItemText>Terms & Conditions</ItemText>
                            </a>
                        </Item>
                        <Item>
                            <a href="https://app.termly.io/document/privacypolicy/bd3aa69c-2c37-403f-8dac-38aec47a6b47" style={{ textDecoration: 'none' }} >
                                <ItemText>Privacy Policy</ItemText>
                            </a>
                        </Item>
                        <Item>
                            <a href="https://app.termly.io/document/cookie-policy/0b51936f-2b8e-4a65-b35b-5ecaec25dfd0" style={{ textDecoration: 'none' }} >
                                <ItemText>Cookies</ItemText>
                            </a>
                        </Item>
                    </ItemWrap>
                </ColB>
                <ColB>
                    <Header>
                        <HeaderText>About</HeaderText>
                    </Header>
                    <ItemWrap>
                        <Item>
                            <Link to={`/about`} style={{ textDecoration: 'none' }}>
                                <ItemText>About Us</ItemText>
                            </Link>
                        </Item>
                        <Item>
                            <Link to={`/contact`} style={{ textDecoration: 'none' }}>
                                <ItemText>Contact</ItemText>
                            </Link>
                        </Item>
                        <Item>
                            <a href="https://www.indeed.com/cmp/Re--hydrate-Wellness/jobs" style={{ textDecoration: 'none' }} >
                                <ItemText>Join Us</ItemText>
                            </a>
                        </Item>
                    </ItemWrap>
                </ColB>
            </RowA>
            <RowC>
                <C2Text>These statements have not been evaluated by the FDA. Vitamin supplementation is for wellness only, and not intended to cure or treat diseases</C2Text>
            </RowC>

            <RowB>
                <Copyright>
                    <CText>Copyright 2022 Rehydrate Wellness</CText>
                </Copyright>
                <DAttribute>
                    <a href="https://infusedigitals.com.ng" style={{ textDecoration: 'none' }} >
                        <DText>Developed by infuse digitals</DText>
                    </a>
                </DAttribute>
            </RowB>
        </Container>
    );
}
