import { Category, ListAltOutlined, CloseOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import {serviceList} from '../data';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { mobile } from '../responsive';
import { makeStyles } from '@material-ui/core/styles';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 130px 20px 20px 20px;
`;
const ColA = styled.div`
    width: 140px;
    display: flex;
    margin-right: 10%;
    flex-direction: column;
    ${mobile({ 
        display: "none"
    })}
`;
const CatHead = styled.div`
  margin-bottom: 30px;
`;
const CatText = styled.span`
    color: #323232;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
`;
const CatBar = styled.div`

`;
const Categories = styled.div`

`;
const CatItem = styled.div`
    margin-bottom: 15px;
`;
const CItemText= styled.div`
    color: #323232;
    font-size: 14px;
    font-weight: 300;
    text-transform: capitalize;
    transition: all 0.3s ease;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        color: #de7bb1;
        letter-spacing: 2px;
    }
`;


const ColB = styled.div`
    width: 65%;
    height: min-content;
    ${mobile({ 
        width: "100%"
    })}
`;
const Products = styled.div`
    width: 100%;
    height: min-content;
`;
const ProductItem = styled.div`
    width: 100%;
    display: flex;
    height: min-content;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #00000030;
`;
const ImageWrap = styled.div`
    width: 30%;
    margin-right: 20px;
`;
const Image = styled.img`
 width: 100%;
`;
const ProductInfo = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
`;
const ProductName = styled.div`
    color: #323232;
    font-size: 21px;
    font-weight: 900;
    text-transform: capitalize;
    display: block;
    margin-bottom: 10px;
`;
const Desc = styled.div`
    color: #323232;
    width: 80%;
    font-size: 16px;
    font-weight: 300;
    display: block;
    margin-bottom: 10px;
    ${mobile({ 
        width: "100%"
    })}
`;
const Ingredient = styled.div`
    color: #de7bb1;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
`;
const IngHead = styled.div`
    color: #323232;
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ 
        flexDirection: "column"
    })}
`;
const Cta = styled.div`
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
`;
const CtaText = styled.span`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
`;
const Price = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    ${mobile({ 
        marginBottom: "20px"
    })}

`;
const PriceText = styled.span`
    color: #de7bb1;
    font-size: 18px;
    font-weight: 600;
`;



const PaginationWrap = styled.div`
    margin: 40px 0px;
    width: min-content;
    ${mobile({ 
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    })}

`;
const MobCategories = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0px;
    top: ${props=>props.dragging === false ? "102vh" : 0};
    right: 0px;
    background-color: #080808;
    display: none;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    z-index: 15;
    transition: all 0.3s ease;
    overflow-y:auto;
    @media (max-width: 768px) {
        display: flex;
    }
`;
const MobCatWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
`;
const MobCatItem = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
`;
const MobCategoryBtn = styled.div`
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #000;
    position: fixed;
    bottom: 30px;
    left: 20;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 14;
    @media (max-width: 768px) {
        display: flex;
    }
`;
const useStyles = makeStyles({
    removeMobCat: {
        color: '#f7238d', 
        fontSize: 32, 
        cursor: 'pointer',
        position: 'absolute',
        top: 30,
        right: 30,
        transition: 'all 0.3s ease',
    },
    MobCatBtn: {
        color: '#f7238d', 
        fontSize: 24, 
    },
  });
export default function RwServices() {
    const classes = useStyles();
    const location = useLocation();
    const cat = location.pathname.split("/")[2];
    const [services, setServices] = useState([]);
    const [isMobCatOpen, setIsMobCatOpen] = useState(false);

    useEffect(() => {
        document.title = "Our Services | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    
    // PAGINATION

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(4);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    useEffect(()=>{
        if(!cat) {
            setServices(serviceList);
        }else {         
            let services1 = serviceList.filter(function (e) {
                return (e.category === cat);
            })
            setServices(services1);
        }
        setCurrentPage(1);
    },[cat]);

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0)
    };

    return (
        <Container>
            <ColA>
                <CatHead>
                    <CatText>Categories</CatText>
                    <CatBar />
                </CatHead>
                <Categories>
                    <CatItem>
                        <Link to={`/services`} style={{ textDecoration: 'none' }}>
                            <CItemText>All</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/services/ivdrips`} style={{ textDecoration: 'none' }}>
                            <CItemText>IV Drips</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/services/boosters`} style={{ textDecoration: 'none' }}>
                            <CItemText>Boosters</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/services/shots`} style={{ textDecoration: 'none' }}>
                            <CItemText>Shots</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/services/chemical_peels`} style={{ textDecoration: 'none' }}>
                            <CItemText>Chemical Peels</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/concierge`} style={{ textDecoration: 'none' }}>
                            <CItemText>Concierge</CItemText>
                        </Link>
                    </CatItem>
                    <CatItem>
                        <Link to={`/nad`} style={{ textDecoration: 'none' }}>
                            <CItemText>NAD+</CItemText>
                        </Link>
                    </CatItem>
                </Categories>
            </ColA>


            <ColB>
                <Products>
                    {services.slice(indexOfFirstPost, indexOfLastPost).map((service) => (
                        <ProductItem key={service.id}>
                            <ImageWrap>
                                <Image src={service.img}/>
                            </ImageWrap>
                            <ProductInfo>
                                <ProductName>{service.name}</ProductName>
                                <Desc>
                                    {service.desc}
                                </Desc>
                                {
                                    service.ingredients != "" && 
                                    <Ingredient>
                                        <IngHead>Ingredient:</IngHead>{service.ingredients}
                                    </Ingredient>
                                }
                                <Bottom>
                                    <Price>
                                        <PriceText>{service.price > 0 ? "$" + service.price : ""}</PriceText>
                                    </Price>
                                    <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                                        <Cta>
                                            <CtaText>Book Now</CtaText>
                                        </Cta>
                                    </a>
                                </Bottom>
                            </ProductInfo>
                        </ProductItem>
                    ))}
                    
                </Products>
                <PaginationWrap>
                <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={services.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </PaginationWrap>
                <MobCategoryBtn onClick={()=>setIsMobCatOpen(true)}>
                    <ListAltOutlined 
                        className={classes.MobCatBtn}
                    />
                </MobCategoryBtn>
                <MobCategories dragging={isMobCatOpen}>
                        <CloseOutlined 
                            className={classes.removeMobCat}
                            onClick={()=>setIsMobCatOpen(false)}
                        />
                        <MobCatWrap>
                            <Link to={`/services`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>All Services</MobCatItem>
                            </Link>
                            <Link to={`/services/ivdrips`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>IV Drips</MobCatItem>
                            </Link>
                            <Link to={`/services/boosters`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>IV Add Ons</MobCatItem>
                            </Link>
                            <Link to={`/services/shots`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>Shots</MobCatItem>
                            </Link>
                            <Link to={`/services/chemical_peels`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>Chemical Peels</MobCatItem>
                            </Link>
                            <Link to={`/concierge`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>Concierge</MobCatItem>
                            </Link>
                            <Link to={`/nad`} style={{ textDecoration: 'none' }} onClick={()=>setIsMobCatOpen(false)}>
                                <MobCatItem>NAD+</MobCatItem>
                            </Link>                   
                        </MobCatWrap>
                    </MobCategories>
            </ColB>
        </Container>
    );
}
