import styled from 'styled-components';
import { mobile } from '../responsive';
import RwHomeHero from '../components/RwHomeHero';
import RwHomeAbout from '../components/RwHomeAbout';
import RwHomeServices from '../components/RwHomeServices';
import RwHomeTeam from '../components/RwHomeTeam';
import RwHomeProducts from '../components/RwHomeProducts';
import { useEffect } from 'react';
import Testimonial from '../components/Testimonial';

const Container = styled.div`
    width: 100%;
`;
const TestimonialContainer = styled.div`
    width: 100%;
    padding: 50px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const TestimonialWrap = styled.div`
    width: 90%;
    ${mobile({ 
        width: "100%",
    })}
`;

export default function RwHome() {

    useEffect(() => {
        document.title = "Rehydrate Wellness | Home | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <RwHomeHero />
            <RwHomeAbout />
            <RwHomeServices />
            <RwHomeTeam />
            <RwHomeProducts />
            <TestimonialContainer>
                <TestimonialWrap>
                    <Testimonial />
                </TestimonialWrap>
            </TestimonialContainer>
        </Container>
    );
}
