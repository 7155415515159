import styled from 'styled-components';
import { mobile } from '../responsive';
import ProductImg1 from '../assets/images/products/product_img1.jpg';
import ProductImg2 from '../assets/images/products/product_img2.jpg';
import ProductImg3 from '../assets/images/products/product_img3.jpg';
import ProductImg4 from '../assets/images/products/product_img4.jpg';


const Container = styled.div`
    width: 100%;
    padding: 50px 30px;
    overflow: hidden;
    box-sizing: border-box;
    ${mobile({ 
         padding: "40px 20px"
    })}
`;
const HeadSection = styled.div`
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SubHeader = styled.h3`
    color: #323232;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
    })}
`;
const SectionHeader = styled.div`
    color: #de7bb1;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
`;
const Slide = styled.div`
    width: 120%;
    height: min-content;
    position: relative;
    display: flex;
    overflow: hidden;
    transition: all 1s ease;
    left: ${(props) => props.slideIndex}%;
    ${mobile({ 
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "center"
    })}
`;
const Item = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    ${mobile({ 
        width: "100%",
        marginRight: "10px",
        marginLeft: "10px",
        marginBottom: "60px",
    })}
`;
const Image = styled.img`
 width: 100%;
 background-color: #909091;
`;
const ItemName = styled.h3`
    color: #323232;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 10px;

`;
const Price = styled.span`
    color: #f7238d;
    font-size: 22px;
    font-weight: 600;
    display: block;
`;
const Desc = styled.div`
    color: #323232;
    font-size: 14px;
    font-weight: 300;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
`;
const Cta = styled.div`
    top: 50px;
    margin-bottom: 50px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &:hover {
        background-color: #f3aad2;
    }
`;
const CtaText = styled.div`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    white-space: nowrap;
    text-transform: uppercase;
`;

export default function RwHomeProducts() {


    return (
        <Container>
            <HeadSection>
                <SubHeader>Products & Merchandise</SubHeader>
                <SectionHeader>Our featrured</SectionHeader>
                <SectionHeader>products</SectionHeader>
            </HeadSection>

            <Slide slideIndex={0}>
                <Item>
                    <Image src={ProductImg1} />
                    <ItemName>
                        GlutaTonic Spray
                    </ItemName>
                    <Price>$47.99</Price>
                    <Desc>
                         Dramatically raise energy levels, strengthen the immune system, 
                         improve athletic performance...
                    </Desc>
                    <a href="https://rehydratewellness.square.site/product/glutatonic-spray/9?cs=true&cst=custom" style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>ADD TO CART</CtaText>
                        </Cta>
                    </a>
                </Item>
                <Item>
                    <Image  src={ProductImg2} />
                    <ItemName>
                        Lipo Bc Tablets
                    </ItemName>
                    <Price>$31.99</Price>
                    <Desc>
                        Burn fat and increase metabolism.
                        Combine with weekly Slim Shot for increased and fast results.
                        30 days supply
                    </Desc>
                    <a href="https://rehydratewellness.square.site/product/Lipo-BC/20?cs=true&cst=custom" style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>ADD TO CART</CtaText>
                        </Cta>
                    </a>
                </Item>
                <Item>
                    <Image  src={ProductImg3} />
                    <ItemName>
                        Be-Thin Spray
                    </ItemName>
                    <Price>$49.99</Price>
                    <Desc>
                        Fast acting fat burner for fast results.
                        Works great with our Slim Shot for increased results.
                    </Desc>
                    <a href="https://rehydratewellness.square.site/product/be-thin/19?cs=true&cst=custom" style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>ADD TO CART</CtaText>
                        </Cta>
                    </a>
                </Item>
                <Item>
                    <Image src={ProductImg4} />
                    <ItemName>
                        Prothin Packs
                    </ItemName>
                    <Price>$16.99 - $60.00</Price>
                    <Desc>
                        Enhance weight loss compliance and eliminate dosage errors. 
                        Just one packet daily...
                    </Desc>
                    <a href="https://rehydratewellness.square.site/product/prothin-packs/10?cs=true&cst=custom" style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>ADD TO CART</CtaText>
                        </Cta>
                    </a>
                </Item>
            </Slide>

        </Container>
    );
}
