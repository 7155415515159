import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import { Link } from "react-router-dom";
import { withStyles, makeStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTypography from '@material-ui/core/Typography';
import { useEffect } from 'react';

const Container = styled.div`
    width: 100%;
`;
const RowA = styled.div`
    width: 100%;
    padding: 130px 30px 120px 30px;
    display: flex;
    justify-content: center;
    ${mobile({ 
        flexDirection: "column",
    })}
`;
const ColA = styled.div`
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    ${mobile({ 
        marginRight: "0",
        marginBottom: "70px"
    })}
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const ColB = styled.div`
    width: 60%;
    ${mobile({ 
        width: "100%",
    })}
`;
const ContactUs = styled.div`
  margin-top: 40px;
  color: #323232;
  font-size: 20px;
  font-weight: 600;
  display: flex;
`;
const ContactLink = styled.div`
  color: #de7bb1;
  cursor: pointer;
  margin-left: 10px;
`;
const Accordion = withStyles({
    root: {
      border: 'none',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#ffffff',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const Typography = withStyles({
    root: {
      fontWeight: 600,
      fontSize: 16,
      fontFamily: 'Urbanist',
      color: '#323232',
    },
  })(MuiTypography);

  const useStyles = makeStyles({
    faqHead: {
        color: '#de7bb1',
        fontWeight: '600',
        fontSize: 18, 
        cursor: 'pointer',
        transition: 'all 0.5s ease',
    },
  });

export default function RwFAQ() {
  const classes = useStyles();
  useEffect(() => {
    document.title = "FAQ | Rehydrate Wellness | Hydration Reimagined";
    window.scrollTo(0, 0)
  }, []);

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container>
            <RowA>
                <ColA>
                    <SubHeader>FAQ</SubHeader>
                    <Header>Frequently</Header>
                    <Header>asked</Header>
                    <Header>questions</Header>
                </ColA>
                <ColB>
                    <div>
                        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography className={classes.faqHead}>DOES IT HURT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              When an IV needle is placed, it can cause some slight discomfort. You may feel a small sting or 
                              pinch for a few seconds when the needle is inserted in your arm or hand. If you're particularly 
                              sensitive to needles, you may ask for a numbing cream, so you don't feel the needle.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography className={classes.faqHead}>WHAT RESULTS CAN I EXPECT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Most of our clients see an increase in energy levels and an overall health-enhancing boost. Optimum vitamin 
                              and mineral levels support proper metabolism, cortisol levels, cellular health, mood, and good sleep patterns. 
                              Vitamin therapy can also be an immune system boost which is very helpful during the winter months and during 
                              allergy season.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography className={classes.faqHead}>IS IT SAFE?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Yes, vitamin IV therapy is safe provided it is administered by a licensed medical practitioner and overseen 
                              by a Medical Director. We use high-quality vitamins, minerals, and amino acids sourced from certified suppliers. 
                              IV therapy has been utilized for over 50 years and was developed by Dr. John Myers (the Myer’s cocktail).
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography className={classes.faqHead}>HOW OFTEN SHOULD I GET AN IV FOR IT TO BE EFFECTIVE?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              This would depend on your nutritional and wellness goals. You may see effects after your first visit. However, 
                              for sustained long-term wellness and nutritional needs we recommend a biweekly treatment. Our membership packages 
                              allow you to maximize your wellness routine at great value. Please learn more at rehydratewellness.com/memberships
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography className={classes.faqHead}>WHY NOT JUST TAKE SUPPLEMENTS?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Vitamin delivery via IV therapy guarantees 99.9% absorption of vitamins, delivering them straight to your 
                              bloodstream for maximum benefit, compared to 8% absorption via oral methods.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                            <Typography className={classes.faqHead}>IS THERE A REASON I SHOULD NOT DO AN IV?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              While we strongly believe in the health and wellness benefits of IV hydrations and other service we provide, 
                              unfortunately we may have to decline a service due to applicable medical concerns and or history as.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                            <Typography className={classes.faqHead}>HOW DO I GET STARTED?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Easy! You may click on the service link to book a service for a time best convenient for you. Should you 
                              have more questions, you may give us a call at 614.532.1511, you may chat with one of our clinicians or nurses 
                              using the chat feature on the lower right corner of our website. Walk ins are also welcomed. Our address can 
                              be found on our contact page
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                            <Typography className={classes.faqHead}>IS THERE ANYTHING I SHOULD DO BEFORE TREATMENT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              For best result, we recommend drinking at least three cups of water and a snack prior to your treatment
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                            <Typography className={classes.faqHead}>HOW LONG DOES IV TAKE?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              On Average, IV Therapy takes 30-40 minutes depending on the amount of fluid or type of service being administered  
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                            <Typography className={classes.faqHead}>WHAT ARE THE BENEFITS OF IV HYDRATION THERAPY?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Re-Hydrate Wellness IV Hydration Therapy is a safe, powerful method to replenish fluids and nutrients with 100% 
                              absorption of electrolytes, vitamins, minerals, amino acids, and antioxidants. Our service menu is formulated 
                              to maximize your hydration, health, performance, and recovery. All our ingredients are sourced from FDA approved facilities.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                            <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                            <Typography className={classes.faqHead}>WHAT ARE BOOSTERS?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Boosters are a great way to take advantage of your IV Therapy by adding med/mineral to any drip that does not already include it.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                            <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                            <Typography className={classes.faqHead}>WHAT IF I AM PREGNANT OR BREASTFEEDING?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              If you are currently pregnant, we may only administer IV treatment with guidance from your OB physician, 
                              we may also provide certain IV Therapies when you are breastfeeding to help boost energy, immunity and recover.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                            <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                            <Typography className={classes.faqHead}>DO I NEED AN APPOINTMENT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              While appointments are not required, we encourage you to make an appointment to guarantee service. We accommodate 
                              walk-ins but sometimes our location gets very busy. We can book your appointment 1 year in advance or as little as 
                              an hour before treatment at the Gahanna Wellness Center. We also offer concierge service.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                            <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
                            <Typography className={classes.faqHead}>DO I NEED ANY TESTING DONE PRIOR TO TREATMENT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              We do not require any testing prior to your visit or treatment, we do conduct a quick medical assessment on site prior to your treatment.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                            <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
                            <Typography className={classes.faqHead}>DO YOU ACCEPT HEALTH INSURANCE?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              No. We do not accept any insurance at this time.  But we do accept FSA or HSA cards.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                            <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
                            <Typography className={classes.faqHead}>RE-HYDRATE WELLNESS OR THE EMERGENCY ROOM?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              We are not a substitute for medical attention, and while the ER may offer some hydration service(s) we pride 
                              ourselves of our great service, great value, and comfort. For emergency, please call 911. Cannot make it into 
                              our Gahanna location? We offer concierge services for an additional $75 service fee. Please visit 
                              rehydratewellness.com/concierge to schedule you are your next concierge appointment
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                            <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
                            <Typography className={classes.faqHead}>IS THERE A MINIMUM AGE TO RECEIVE TREATMENT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Yes, we only treat anyone 18 and over.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                            <AccordionSummary aria-controls="panel18d-content" id="panel18d-header">
                            <Typography className={classes.faqHead}>CAN YOU COME TO ME?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                              Yes. We offer concierge service (for individuals, businesses and for groups). Please visit our concierge 
                              page rehydratewellness.com/concierge to book an appointment.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                      <ContactUs>
                        Have more questions?
                        <Link to={`/contact`} style={{ textDecoration: 'none' }} >
                            <ContactLink>
                                Please contact us
                            </ContactLink>
                          </Link>
                      </ContactUs>
                </ColB>
            </RowA>
        </Container>
    );
}
