import styled from 'styled-components';
import { mobile } from '../responsive';
import BgImg1 from '../assets/images/team_b2.jpg';
import { Link } from "react-router-dom";
import { useEffect } from 'react';



const Container = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    background-color: #fae4e6;
    flex-direction: column;
    align-items: center;
    ${mobile({ 

    })}
`;
const Col = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    ${mobile({ 

    })}
`;
const InfoWrap = styled.div`
    width: 350px;
    position: relative;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 30px;
    overflow-wrap: break-word;
    background-color: #00000050;
    ${mobile({ 
        left: "calc(50% - 175px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    })}
`;
const HeaderWrap = styled.div`
`;
const Header = styled.h1`
    color: #fff;
    font-size: 36px;
    font-weight: 900;
    line-height: 0.9;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Desc = styled.span`
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const CTA = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
    ${mobile({ 
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    })}
`;
const CTAText = styled.span`
    color: #fff;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    white-space: nowrap;
`;


export default function RwHomeTeam() {


    return (
        <Container>
            <Col style={{ backgroundImage: `url(${BgImg1})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <Header>
                                Meet the
                            </Header>
                            <Header>
                                team
                            </Header>
                        </HeaderWrap>
                        <Desc>
                            Passionate about integrated health and wellness, while promoting optimum wellbeing from within
                            through hydration
                        </Desc>
                        <Link to={`/about`} style={{ textDecoration: 'none' }}>
                            <CTA>
                                <CTAText>LEARN MORE</CTAText>
                            </CTA>
                        </Link>
                    </InfoWrap>
                </Col>
        </Container>
    );
}
