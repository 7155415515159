import styled from 'styled-components';
import MainLogo from '../../assets/images/main_logo.png';
import { mobile } from '../../responsive';
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {  MenuOutlined, ClearOutlined } from "@material-ui/icons";

const Container = styled.div`
    width: 100%;
    display: flex;
    position: fixed;
    box-sizing: border-box;
    z-index: 10;
`;
const Nav = styled.div`
    width: 100%;
    display: flex;
    transition: all 0.3s ease;
    justify-content: space-between;
    padding: 20px 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0dfdf50;
    position: fixed;
    background-color: ${props=> props.scrollPosition > 50 && "#fff"};
    ${mobile({ 
        padding: "20px 20px"
    })}
`;
const LogoWrap = styled.div`
 width: 100px;
`;
const Logo = styled.img`
    width: 100%;
    ${mobile({ 
        width: "100px"
    })}
`;
const Menu = styled.div`
    width: 75%;
    transition: all 0.5s ease;
    @media (max-width: 768px) {
        transform: ${props=>props.isNavOpen === true ? "translateX(-100%)" : "translateX(100%)"};
    }
    ${mobile({ 
        width: "100%",
        position: "fixed",
        backgroundColor: "#323232",
        top: "0px",
        right: "-100%",
        bottom: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    })}
`;
const NavWrap = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ 
        flexDirection: "column",
        marginTop: "0px"
    })}
`;
const ColA = styled.div`
    display: flex;
    margin-right: 40px;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
        marginRight: "0px"
    })}
`;
const ItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    margin-right: ${props=> props.rightMargin === true && "20px"};
    ${mobile({ 
        marginRight: "0px",
        marginBottom: "20px"
    })}
`;
const ItemBWrap = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    /* background-color: #de7bb1; */
    margin-right: ${props=> props.rightMargin === true && "20px"};
    ${mobile({ 
        marginRight: "0px",
        marginBottom: "20px"
    })}
`;
const Item = styled.span`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease;
    ${mobile({ 
        color: "#ffffff",
        fontSize: "22px"
    })}
    &:hover {
        color: #616060;
    }
`;
const ItemBar = styled.div`
    width: ${props=> props.currentNavUrl === props.urlValue ? "100%" : "0%"}; 
    height: 2px;
    margin-top: 5px;
    background-color: #de7bb1;
    transition: all 0.5s ease;
    ${mobile({ 

    })}
`;
const ItemB = styled.span`
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    ${mobile({ 
        color: "#ffffff",
        fontSize: "18px"
    })}
    &:hover {
        color: #616060;
    }
`;


const ColB = styled.div`
    display: flex;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
    })}
`;
const ClearWrap = styled.div`
    display: none;
    ${mobile({ 
        display: "flex"
    })}
`;

const Bar = styled.div`
    display: none;
    ${mobile({ 
        display: "flex"
    })}
`;

export default function RwMainNav() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();

    const currentNavUrl = (location.pathname.split("/")[1]);

    const ViewPointHeight = window.innerHeight;

    const handleChange = () => {    
        setIsNavOpen(false);  
      };

    const closeNavMenu = () => {
        setIsNavOpen(false);
      };
      const openNavMenu = () => {
        setIsNavOpen(true);
      };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };

      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);


    return (
        <Container>
            <Nav scrollPosition={scrollPosition} Vheight={ViewPointHeight}>
                <LogoWrap>
                    <Link to={`/`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                        <Logo src={MainLogo} />
                    </Link>
                </LogoWrap>
                <Menu isNavOpen={isNavOpen}>
                    <NavWrap>
                        <ColA>
                            <Link to={`/`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemWrap 
                                    rightMargin={true}
                                >
                                    <Item>Home</Item>
                                    <ItemBar 
                                        urlValue="" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemWrap>
                            </Link>
                            <Link to={`/about`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemWrap 
                                    rightMargin={true}
                                >
                                    <Item>About</Item>
                                    <ItemBar 
                                        urlValue="about" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemWrap>
                            </Link>
                            <Link to={`/services`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemWrap 
                                    rightMargin={true}
                                >
                                    <Item>Services</Item>
                                    <ItemBar 
                                        urlValue="services" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemWrap>
                            </Link>
                            <a href="https://rehydratewellness.square.site/products" style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemWrap 
                                    rightMargin={true}
                                >
                                    <Item>Products & Merchandise</Item>
                                    <ItemBar 
                                        urlValue="products" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemWrap>
                            </a>
                            <Link to={`/contact`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemWrap 
                                    rightMargin={false}
                                >
                                    <Item>Contact</Item>
                                    <ItemBar 
                                        urlValue="contact" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemWrap>
                            </Link>
                        </ColA>

                        <ColB>
                            <Link to={`/memberships`} style={{ textDecoration: 'none' }} onClick={handleChange}>
                                <ItemBWrap
                                    rightMargin={false}
                                >
                                    <ItemB>Memberships</ItemB>
                                    <ItemBar 
                                        urlValue="memberships" 
                                        currentNavUrl={currentNavUrl}
                                    />
                                </ItemBWrap>
                            </Link>
                        </ColB>
                    </NavWrap>
                    <ClearWrap>
                        <ClearOutlined 
                             style={{ 
                                color: '#fff', 
                                fontSize: 28,
                                position: 'absolute',
                                top: 30,
                                right: 30
                            }}
                            onClick={closeNavMenu}
                        />
                    </ClearWrap>
                </Menu>
                <Bar>
                    <MenuOutlined 
                         onClick={openNavMenu}
                    />
                </Bar>

            </Nav>
        </Container>
    );
}
