import styled from 'styled-components';
import { mobile } from '../responsive';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState, useRef } from 'react';
import { 
    ArrowForwardIosOutlined,
    ArrowBackIosOutlined,
} from '@material-ui/icons';

import UserImg1 from '../assets/images/testimonial/user1.jpg';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import '../style/App.scss';

import {testimonials} from '../data';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({ 
 
    })}  
`;

const Wrapper = styled.div`
    width: 100%;
    //height: max-content; 
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Title = styled.div`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    
`;
const SubTitle = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    ${mobile({ 
        fontSize: "28px",
    })}
`;
const Body = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    position: relative;
`;
const ArrowIconsWrap = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    display: flex;
    position: absolute;
    z-index: 10;
    ${mobile({ 
        justifyContent: "center",
        display: "none"
    })}
`;
const ArrowHolder = styled.div`
    //margin-left: calc(10% + 220px);
    width: 100%;
    //margin-left: 10%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    //border: 1px solid #000;
    ${mobile({ 
        marginLeft: "0%",
    })}
`;
const ArrowIcon = styled.div`
    width: 40px;
    height: 100%;
    background-color: #de7bb1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
        background-color: #212121;
    }
`;

const TestimonyWrap = styled.div`
    width: 100%;
    margin-bottom: 40px;
    overflow: hidden;
    border: 1px solid #000;
`;
const TestimonyHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid red;
`;
const Testimony = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    ${mobile({ 
        width: "100%",
        justifyContent: "space-between",
        padding: "0px 15px 0px 15px"
    })}
`;
const Left = styled.div`
    width: 170px;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    ${mobile({ 
        width: "30px",
        marginRight: "30px"
    })}
`;
const Image = styled.div`
    width: 100px;
    height: 100px;
    background-color: #ffc5e5;
    background-size: cover;
    background-position: center center;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile({ 
        width: "40px",
        height: "40px",
    })}
`;
const Initial = styled.div`
    color: #323232;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    ${mobile({ 
        fontSize: "14px",
        fontWeight: "600"
    })}
`;
const Name = styled.div`
    color: #323232;
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    ${mobile({ 
        fontSize: "14px",
        fontWeight: "600"
    })}
`;
const Right = styled.div`
    width: calc(100% - 220px);
    display: flex;
    flex-direction: column;
    ${mobile({ 
        width: "calc(100% - 90px)",
    })}
`;
const Message = styled.div`
    color: #323232;
    font-size: 21px;
    font-weight: 500;
    line-height: 29px;
    ${mobile({ 
        fontSize: "14px",
    })}
`;






const useStyles = makeStyles({
    ArrowIcons: {
        color: '#fff;', 
        fontSize: 20,
        zIndex: 10,
    },
  });
export default function Testimonial() {
    const classes = useStyles();

    const swiperNavPrevRef = useRef(null);
    const swiperNavNextRef = useRef(null);
    SwiperCore.use([Autoplay])


    return (
        <Container>
            <Wrapper>
                <Header>
                    <Title>Testimonials</Title>
                    <SubTitle>What our clients say</SubTitle>
                </Header>
                <Body>
                    <ArrowIconsWrap>
                        <ArrowHolder>
                                <div className="swiperNavNext" ref={swiperNavNextRef}>
                                    <ArrowIcon>
                                        <ArrowBackIosOutlined className={classes.ArrowIcons} /> 
                                    </ArrowIcon>
                                </div>                         
                                <div className="swiperNavPrev" ref={swiperNavPrevRef}>
                                    <ArrowIcon>
                                        <ArrowForwardIosOutlined className={classes.ArrowIcons} />
                                    </ArrowIcon>
                                </div>
                        </ArrowHolder>
                    </ArrowIconsWrap>
                    <Swiper
                        modules={[Navigation ]}
                        navigation={{
                            prevEl: swiperNavPrevRef.current,
                            nexEl: swiperNavNextRef.current,
                        }}
                        speed={500}
                        slidesPerView={1}
                        spaceBetween={20}
                        loop
                        className="myswiper"
                        autoplay={
                            {delay:4000}
                        }
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = swiperNavPrevRef.current;
                            swiper.params.navigation.nextEl = swiperNavNextRef.current;
                            swiper.navigation.init();
                        }}
                        >
                        {testimonials.map((item) => (
                            <SwiperSlide key={item.id} className="swiperslide">
                                <Testimony>
                                    <Left>
                                        <Image>
                                            <Initial>{item.name.split(" ").map((n)=>n[0]).join("")}</Initial>
                                        </Image>
                                        <Name>{item.name}</Name>
                                    </Left>
                                    <Right>
                                        <Message>
                                            {item.testimony}
                                        </Message>
                                    </Right>
                                </Testimony>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Body>
            </Wrapper>

        </Container>
    );
}
