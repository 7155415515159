import styled from 'styled-components';
import { mobile, tablet } from '../responsive';
import { useEffect } from 'react';

import BgImg1 from '../assets/images/membership2.jpg';
import ImgA1 from '../assets/images/memberships/refresh.jpg';
import ImgA2 from '../assets/images/memberships/refresh_back.jpg';
import ImgB1 from '../assets/images/memberships/restore.jpg';
import ImgB2 from '../assets/images/memberships/restore_back.jpg';
import ImgC1 from '../assets/images/memberships/revitalize.jpg';
import ImgC2 from '../assets/images/memberships/revitalize_back.jpg';


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const RowA = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    background-color: #fae4e6;
    flex-direction: column;
    align-items: center;
    ${mobile({ 

    })}
`;
const ColA = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    ${mobile({ 

    })}
`;
const InfoWrap = styled.div`
    width: 350px;
    position: relative;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    padding: 30px;
    overflow-wrap: break-word;
    background-color: #ffffff50;
    ${mobile({ 
        left: "calc(50% - 175px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    })}
`;
const HeaderWrap = styled.div`
`;
const HeaderText = styled.h1`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 0.9;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Desc = styled.span`
    color: #666666;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const CTA = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
    ${mobile({ 
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    })}
`;
const CTAText = styled.span`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
`;

const RowB = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 40px;
`;
const RowC = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0px 0px 30px 0px;
    box-sizing: border-box;
    ${mobile({ 
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 20px 30px 20px"
    })}
`;
const SubHeader = styled.h3`
    color: #de7bb1;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    ${mobile({ 
        marginTop: "30px",
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    ${mobile({ 
        textAlign: "center"
    })}
`;

const TierCol = styled.div`
    width: 471px;
    height: 269px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 20px 10px;
    box-sizing: border-box;

    ${mobile({ 
        marginBottom: "20px",
        width: "267px",
        height: "152px"
    })}
    ${tablet({
        width: "267px",
        height: "152px"
    })}
`;
const ImageHolder = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    ${mobile({ 
    })}
`;
const ImgFront = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    &:hover {
        opacity:0;
        cursor: pointer
    }
`;
const ImgBack = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
`;
// const Tier = styled.h3`
//     color: #de7bb1;
//     font-size: 18px;
//     font-weight: 600;
//     text-transform: uppercase;
// `;
// const Info = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin: 30px 0px;
//     box-sizing: border-box;
// `;
// const TierItem = styled.span`
//     display: block;
//     text-align: center;
//     color: #323232;
//     font-size: 16px;
//     font-weight: 600;
//     margin-bottom: 10px;
// `;
// const Price = styled.span`
//     display: block;
//     text-align: center;
//     color: #de7bb1;
//     font-size: 16px;
//     font-weight: 600;
// `;
const RowD = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`;
const CTA2 = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
`;

export default function RwMemberships() {

    useEffect(() => {
        document.title = "Memberships | Rehydrate Wellness | Hydration Reimagined";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <RowA>
                <ColA style={{ backgroundImage: `url(${BgImg1})`}}>
                    <InfoWrap>
                        <HeaderWrap>
                            <HeaderText>
                                Maximize your
                            </HeaderText>
                            <HeaderText>
                                wellness
                            </HeaderText>
                            <HeaderText>
                                routine
                            </HeaderText>
                        </HeaderWrap>
                        <Desc>
                        Maximize your wellness routine, while taking advantage of our package discounts
                        </Desc>
                        <a href="https://rehydratewellness.square.site/memberships" style={{ textDecoration: 'none' }}>
                            <CTA>
                                <CTAText>Become a member</CTAText>
                            </CTA>
                        </a>
                    </InfoWrap>
                </ColA>               
            </RowA>
            <RowB>
                <SubHeader>MEMBERSHIPS</SubHeader>
                <Header>Become</Header>
                <Header>a member</Header>
            </RowB>
            <RowC>
                <TierCol>
                    <ImageHolder>
                        <ImgBack src={ImgA2} />
                        <ImgFront src={ImgA1} />
                    </ImageHolder>
                </TierCol>
                <TierCol>
                    <ImageHolder>
                        <ImgBack src={ImgB2} />
                        <ImgFront src={ImgB1} />
                    </ImageHolder>
                </TierCol>
                <TierCol>
                    <ImageHolder>
                        <ImgBack src={ImgC2} />
                        <ImgFront src={ImgC1} />
                    </ImageHolder>
                </TierCol>
            </RowC>
            <RowD>
                <a href="https://rehydratewellness.square.site/memberships" style={{ textDecoration: 'none' }}>
                    <CTA2>
                        <CTAText>Become a member</CTAText>
                    </CTA2>
                </a>
            </RowD>
        </Container>
    );
}
