import styled from 'styled-components';
import { mobile } from '../responsive';
import { Link } from "react-router-dom";
import AboutImg1 from '../assets/images/ivdrip.jpg';
import AboutImg from '../assets/images/ivdrip.jpg';
import VanImg from '../assets/images/van3.jpg';
import CardImg from '../assets/images/card.jpg';



const Container = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    padding: 50px 30px;
    box-sizing: border-box;
    background-color: #fae4e6;
    flex-direction: column;
    align-items: center;
    ${mobile({ 
        padding: "40px 20px"
    })}
`;
const SectionHead = styled.div`
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SubHeader = styled.h3`
    color: #323232;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
    ${mobile({ 
        marginTop: "30px",
        textAlign: "center"
    })}
`;
const SectionHeader = styled.div`
    color: #de7bb1;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    ${mobile({ 
        textAlign: "center"
    })}
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    ${mobile({ 
        flexDirection: "column"
    })}
`;
const ColA = styled.div`
    width: 45%;
    height: min-content;
    background-color: #8a8d8d;
    margin-right: ${props=> props.rightMargin === true && "30px"};
    ${mobile({ 
        width: "100%",
        marginRight: "0px",
        marginTop: "20px"
    })}
`;
const Image = styled.img`
    width: 100%;
`;

const ColB = styled.div`
    width: 45%;
    height: min-content;
    margin-top: 20px;
    margin-right: ${props=> props.rightMargin === true && "30px"};
    ${mobile({ 
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "0px"
    })}
`;
const Header = styled.div`
    color: #323232;
    font-size: 36px;
    font-weight: 900;
    line-height: 1.2;
    ${mobile({ 
        textAlign: "center"
    })}
`;
const Desc = styled.div`
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    ${mobile({ 
        textAlign: "center",
    })}
`;
const Cta = styled.div`
    margin-top: 30px;
    background-color: #de7bb1;
    width: min-content;
    display: flex;
    padding: 7px 15px;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
        background-color: #f3aad2;
    }
`;
const CtaText = styled.div`
    color: #323232;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2px;
    white-space: nowrap;
    text-transform: uppercase;
`;

export default function RwHomeServices() {


    return (
        <Container>
            <SectionHead>
                <SubHeader>Our Services</SubHeader>
                <SectionHeader>Vitamins And Minerals</SectionHeader>
                <SectionHeader>Straight To Your Cells</SectionHeader>
            </SectionHead>
            <Row>
                <ColA rightMargin={true}>
                    <Image src={AboutImg1}/>
                </ColA>
                <ColB rightMargin={false}>
                    <Header>100% Absorption</Header>
                    <Desc>
                        Vitamin delivery via IV therapy guarantees 99.9% absorption of vitamins, delivering them
                        straight to your bloodstream for maximum benefit, compared to 8% absorption via oral
                        methods.
                    </Desc>
                    <a href="https://squareup.com/appointments/book/1bpajir00zzwq7/L88N5T4M1281S/services" style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>Book Now</CtaText>
                        </Cta>
                    </a>
                </ColB>
            </Row>
            <Row>
                <ColB rightMargin={true}>
                    <Header>IV therapy in the</Header>
                    <Header>comfort of your home</Header>
                    <Desc>
                        Can't make it in? We will come to you. Give us a call to schedule your concierge service. You
                        can also type "Concierge" in the chat box to get started in scheduling an appointment
                    </Desc>
                    <Link to={`/concierge`} style={{ textDecoration: 'none' }}>
                        <Cta>
                            <CtaText>Learn More</CtaText>
                        </Cta>
                    </Link>
                </ColB>
                <ColA rightMargin={false}>
                    <Image src={VanImg}/>
                </ColA>
            </Row>
            <Row>
            <ColA rightMargin={true}>
                    <Image src={CardImg}/>
                </ColA>
                <ColB rightMargin={false}>
                    <Header>Maximize your benefits</Header>
                    <Header>by becoming a member</Header>
                    <Desc>
                        Our membership plans afford our clients opportunity to maximize their wellness routine,
                        while taking advantage of our package discounts.
                    </Desc>
                        <a href="https://rehydratewellness.square.site/memberships" style={{ textDecoration: 'none' }}>
                            <Cta>
                                <CtaText>Become A Member</CtaText>
                            </Cta>
                        </a>
                </ColB>
            </Row>

        </Container>
    );
}
