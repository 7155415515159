import MainApp from './layouts/MainApp'
import Home from './pages/RwHome';
import About from './pages/RwAbout';
import Contact from './pages/RwContact';
import Services from './pages/RwServices';
import FAQ from './pages/RwFAQ';
import Memberships from './pages/RwMemberships';
import Concierge from './pages/RwConcierge';
import Nad from './pages/RwNad';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:category" element={<Services />} />
          <Route path="/memberships" element={<Memberships />} />
          <Route path="/concierge" element={<Concierge />} />
          <Route path="/nad" element={<Nad />} />
        </Route>
      </Routes>
    </Router>
  );

};

export default App;



